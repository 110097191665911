<template>
	<page-container :page="page">
		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:booking-id="page.bookingID"
		>
			<template #bgImage>
				<picture>
					<source :srcset="defaults[locale].website.indexIntroImageWebp" type="image/webp" />
					<source :srcset="defaults[locale].website.indexIntroImage" />
					<img
						:src="defaults[locale].website.indexIntroImage"
						:alt="defaults[locale].website.indexIntroImageAlt"
						loading="lazy"
						class="fade-image"
					/>
				</picture>
			</template>
		</main-content>

		<section class="main-content benefits">
			<div class="row">
				<div class="columns column4">
					<h2 class="subtitle">
						{{ defaults[locale].website.benefitsSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].website.benefitsHeader }}
					</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].website.bookingBenefits" :key="key">
							<span>{{ benefit.content }}</span>
						</li>
					</ul>
					<a
						class="button button-cta reserve-now"
						href="https://ibe.sabeeapp.com/properties/Ogygia-Suites-booking/?p=bSpd676312cbc6a2853&l=en"
						target="_blank"
						rel="noopener noreferrer"
					>
						{{ $t('bookNow') }}
					</a>
				</div>
				<div class="side-curve" />
			</div>
			<picture>
				<source
					v-if="defaults[locale].website.bannerImageWebp"
					:srcset="defaults[locale].website.bannerImageWebp"
					type="image/webp"
				/>
				<source :srcset="defaults[locale].website.bannerImage" />
				<img
					class="fade-image"
					:src="defaults[locale].website.bannerImage"
					:alt="defaults[locale].website.bannerImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<promoblocks
			v-if="promoblocksData.length"
			:header="defaults[locale].website.sectionPromotionTitle"
			:subtitle="defaults[locale].website.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="6"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.intro {
	padding: 140px 0;
}

/* Booking benefits */
.benefits {
	position: relative;
	padding: 0;
	background: #d6d1c4;

	.fade-image {
		opacity: 1;
		background: #333;
		width: 68%;
		left: 32%;
	}

	.side-curve {
		width: 240px;
		background: url('~/assets/images/benefits-bg-side.svg') no-repeat top right;
		background-size: cover;
	}

	.subtitle {
		color: #fff;
	}

	.subtitle::before {
		background: url('~/assets/images/subtitle-pre-logo-white.svg') no-repeat center center;
		background-size: 28px;
	}

	.row {
		z-index: 2;
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: stretch;
		min-height: 640px;
	}

	.column4 {
		background: #d6d1c4;
		padding: 130px 50px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
	}

	ul {
		list-style: none;
		font-size: 20px;
		margin: 0;
		width: 100%;
	}

	li {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		margin: 0 0 20px;
		font-size: 17px;

		span {
			width: calc(100% - 70px);
		}
	}

	li::before {
		content: '';
		background: url('~/assets/images/checkmark.svg') no-repeat center center;
		background-size: 36px;
		width: 36px;
		height: 28px;
		display: block;
		margin: 0 30px 0 0;
	}
}

@media (max-width: 1180px) {
	.benefits .column4 {
		width: 50%;
	}
}

@media (max-width: 920px) {
	.benefits .column4 {
		width: 60%;
	}

	.intro {
		padding: 70px 0;
	}
}

@media (max-width: 680px) {
	.benefits {
		.row {
			flex-flow: column wrap;
			overflow: visible;
			min-height: auto;
		}

		.column4 {
			width: 100%;
			padding: 60px 25px 10px;
		}

		.side-curve {
			width: 104%;
			height: 200px;
			background: url('~/assets/images/benefits-bg-bottom.svg') no-repeat top right;
			background-size: cover;
			position: absolute;
			left: -2%;
			right: -2%;
			bottom: -180px;
		}

		.fade-image {
			width: 100%;
			position: relative;
			inset: auto;
			margin-top: 15px;
		}
	}
}
</style>
